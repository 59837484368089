.c-page-header {
	margin: $lsu 0;
}

.c-page-header__layout {
	@include layout-wrapper;
}

.c-page-header__layout-inner {
	max-width: $layout-wrapper-narrow-width - $bsu * 2;
	max-width: calc(#{$layout-wrapper-narrow-width-vars} - #{$bsu * 2});
}

.c-page-header__heading {
	@include font-heading-36b;
	display: block;
	margin-bottom: $lsu;
	&:last-child {
		margin-bottom: 0;
	}

	@include bp("large") {
		@include font-heading-45b;
	}
}

.c-page-header__date {
	display: block;
	margin-bottom: $ssu;

	@include font-heading-14b;
}

.c-page-header__authors {
	@include font-base-18;
}
