// CONTROL.NEWS-LISTING

$news-listing-bp: "large";

.c-news-listing {}

.c-news-listing__layout {
	@include layout-wrapper;
}

.c-news-listing__body {
	background: $grey--lightest;
	padding: $bsu 0;
	@include bp($news-listing-bp) {
		padding: $lsu 0;
	}
}

////////////
// FILTER //
////////////
.c-news-listing__filter {
	@include layout-wrapper;

	padding-top: $bsu;
	padding-bottom: $bsu;
	@include bp($news-listing-bp) {
		padding-top: $lsu;
		padding-bottom: $lsu;
	}
}

.c-news-listing__filter-form {}

.c-news-listing__filter-heading {
	display: block;
	margin-bottom: $ssu;

	@include font-heading-16b;
}

.c-news-listing__filter-body {
	display: flex;

	margin-left: -$bsu;
}

.c-news-listing__filter-list {
	flex-grow: 1;

	@include list-reset;
	display: flex;

	padding-left: $bsu;
	margin-left: -$bsu;
}

.c-news-listing__filter-item {
	flex-grow: 1;

	padding-left: $bsu;
}

.c-news-listing__filter-input {
	@include form-input;
}

.c-news-listing__filter-controls {
	padding-left: $bsu;
}

.c-news-listing__filter-submit {
	@include button;
}

//////////
// ITEM //
//////////
.c-news-listing__list {
	@include list-reset;
	display: flex;
	align-items: stretch;
	flex-wrap: wrap;

	margin-left: -$bsu;
	margin-top: -$bsu;
}

.c-news-listing__list-item {
	width: 100%;
	@include bp("small") {
		width: 50%;
	}
	@include bp("large") {
		width: 25%;
	}

	padding-left: $bsu;
	padding-top: $bsu;

	@include link-transition;
	@include scroll-appear {
		opacity: 0;
		transform: translateY(100px);
	}
}

.c-news-listing__list-item--featured {
	@include bp("small") {
		width: 100%;
	}
	@include bp("large") {
		width: 50%;
	}
}

.c-news-listing__item {
	display: flex;
	flex-direction: column;
	height: 100%;

	position: relative;
	isolation: isolate;

	&:hover,
	&:focus {
		.c-news-listing__item-image {
			transform: scale(1.1);
		}

		.c-news-listing__item-date,
		.c-news-listing__item-heading {
			color: var(--secondary-a);
		}

		.c-news-listing__item-heading-text::after {
			opacity: 1;
		}
	}
}

.c-news-listing__item-figure {
	@include figure;
	overflow: hidden;
}

.c-news-listing__item-image {
	@include link-transition;
}

.c-news-listing__item-body {
	flex-grow: 1;

	background: $white;
	color: $brand;
	color: var(--primary-a);

	.c-news-listing__item--no-image & {
		padding-top: $lsu;

		background-image: url("/content/images/interface/bg/triangle.svg");
		background-position: bottom right;
		background-repeat: no-repeat;
		background-size: 230px;

		padding-bottom: 130px;
	}

	padding: $bsu;

	.c-news-listing__list-item--featured .c-news-listing__item--no-image & {
		// Featured item with no image
		display: block;
		height: 100%;

		padding-top: $lsu;
		background-size: initial;
	}
	.c-news-listing__list-item--featured .c-news-listing__item--has-image & {
		// Featured item with image
		position: absolute;
		bottom: 0;
		left: 0;

		width: 100%;
		min-height: 200px;

		display: flex;
		align-items: flex-end;

		background: linear-gradient(0, $black 0%, $black 50px, transparent 200px, transparent 100%);
		color: $white;

		background: linear-gradient(0, var(--primary-a) 0%, var(--primary-a) 50px, transparent 200px, transparent 100%);
		color: var(--primary-b);
	}
}

.c-news-listing__item-content {}

.c-news-listing__item-tag {
	position: absolute;
	z-index: 1;
	top: 0;
	right: 0;

	padding: $tsu $ssu;
	border-radius: 2px;

	@include font-base-12bu;
}

.c-news-listing__item-date {
	display: block;
	margin-bottom: $ssu;

	@include font-heading-14b;
	@include link-transition(color);
}

.c-news-listing__item-heading {
	display: inline-block;
	padding-right: calc(1em + #{$tsu});

	@include font-heading-18b;
	@include bp($news-listing-bp) {
		@include font-heading-22b;
	}
	@include link-transition(color);
}

.c-news-listing__item-heading-text {
	@include link-arrow;
}
