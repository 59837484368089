﻿// OBJECT.ICON-WRAPPER

@mixin icon-wrapper {
	position: relative;
	display: block;

	&::before {
		@include abs-center;
	}
}

.o-icon-wrapper {
	@include icon-wrapper;
}
