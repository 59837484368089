﻿/* UTILITY.HELPERS */

// Visibility
// Hide only visually, but have it available for screenreaders
// More info: h5bp.com/v
.u-visuallyhidden {
	position: absolute;
	clip: rect(0 0 0 0);
	overflow: hidden;
	width: 1px;
	height: 1px;
	padding: 0;
	border: 0;
	margin: -1px;
}

// Extends the .visuallyhidden class to allow the element to be focusable when navigated to via the keyboard
// More info: h5bp.com/p
u-visuallyhidden.focusable:active,
.u-visuallyhidden.focusable:focus {
	position: static;
	clip: auto;
	overflow: visible;
	width: auto;
	height: auto;
	margin: 0;
}

// Hide from both screenreaders and browsers
// More info: h5bp.com/u
.u-hidden {
	display: none !important;
	visibility: hidden;
}

// Image replacement
// Text indent for IE6/7 fallback
.u-ir {
	background-color: transparent;
	border: 0;
	overflow: hidden;
	*text-indent: -9999px;

	&:before {
		content: "";
		display: block;
		width: 0;
		height: 100%;
	}
}

// Display Helpers
.u-block {
	display: block !important;
}

.u-inline {
	display: inline !important;
}

.u-inline-b {
	display: inline-block !important;
}

// Floats
.u-float--left {
	float: left !important;
}

.u-float--right {
	float: right !important;
}

.u-clear {
	clear: both !important;
}

.u-clear--left {
	clear: left !important;
}

.u-clear--right {
	clear: right !important;
}

// Self clear
// Retaining .clearfix as some other plugins & Umbraco use this
.clearfix {
	@include clearfix;
}

// Text alignment
.u-text--left {
	text-align: left !important;
}

.u-text--right {
	text-align: right !important;
}

.u-text--center {
	text-align: center !important;
}

// Typography
.u-weight--light {
	font-weight: $weight--light !important;
}

.u-weight--normal {
	font-weight: $weight--normal !important;
}

.u-weight--semibold {
	font-weight: $weight--semibold !important;
}

.u-weight--bold {
	font-weight: $weight--bold !important;
}

.u-style--normal {
	font-style: normal !important;
}

.u-style--italic {
	font-style: italic !important;
}

.u-caps {
	text-transform: uppercase !important;
}

@keyframes todoPulse {
	0% {
		opacity: 1;
	}

	90% {
		opacity: 1;
	}

	95% {
		opacity: 0.5;
	}

	100% {
		opacity: 1;
	}
}

html:not(.todo-hide) {
	.todo,
	.todo-fe,
	.todo-be {
		animation: 5s todoPulse linear infinite;
		min-height: 10px;
		min-width: 10px;
	}

	.todo-fe {
		background: #FA0 !important;
	}

	.todo-be {
		background: #0f0 !important;
	}

	.todo,
	.todo-fe.todo-be {
		background: fuchsia !important;
	}
}

// Javascript helpers
// Whole element is clickable so show correct cursor
.js-hot {
	cursor: pointer;
}

// Hide when JS is enabled
.js-hidden {
	display: none;

	.no-js & {
		display: block;
	}
}

.js-lazy-auto,
.js-lazy-bg {

	.no-js & {
		display: none;
	}
}
