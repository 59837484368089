// CONTROL.PEOPLE--MAIN

@use "sass:math";

.c-people--main,
.c-people-listing {
	@include control;

	.c-people__layout {
		@include layout-wrapper;
	}

	.c-people__head {}

	.c-people__heading {
		@include control__heading;

		display: block;
		margin-bottom: $bsu;
	}

	.c-people__introduction {
		@include body-text;

		display: block;
		margin-bottom: $bsu;

		@include bp($layout-wrapper-max-width) {
			flex-shrink: 1;
		}
	}

	.c-people__list {
		@include list-reset;
		@include flex-extend;
		flex-wrap: wrap;
		align-items: stretch;
		margin-left: -$bsu;
		margin-top: -$bsu;
	}

	.c-people__item {
		padding-left: $bsu;
		padding-top: $bsu;

		width: percentage(math.div(1, 1));

		@include bp("small") {
			width: percentage(math.div(1, 2));
		}

		@include bp("medium") {
			width: percentage(math.div(1, 3));
		}

		@include bp("large") {
			width: percentage(math.div(1, 4));

			.l-page--has-sidebar .l-page__main-controls & {
				width: percentage(math.div(1, 2));
			}
		}

		@include bp("extra-large") {
			.l-page--has-sidebar .l-page__main-controls & {
				width: percentage(math.div(1, 3));
			}
		}
	}

	.c-people__controls {
		display: block;
		margin-top: $bsu;

		@include bp("small") {
			&[data-people-count="2"] {
				display: none;
			}
		}
		@include bp("medium") {
			&[data-people-count="3"] {
				display: none;
			}
		}
		@include bp("large") {
			&[data-people-count="4"] {
				display: none;
				.l-page--has-sidebar .l-page__main-controls & {
					display: block;
				}
			}
		}

		.c-people[aria-expanded="true"] & {
			display: none !important;
		}
	}


	// Person
	.c-people__person {
		@include button-reset;
		display: block;

		width: 100%;
		height: 100%;

		position: relative;
		isolation: isolate;
		&:hover,
		&:focus {
			outline: none;

			.c-people__person-image {
				transform: scale(1.1);
			}

			.c-people__person-icon {
				background-color: var(--secondary-a);
			}

			&::before {
				content: "";
				position: absolute;
				z-index: 4;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;

				pointer-events: none;
			}
		}

		&:focus {
			&::before {
				@include focus-outline;
			}
		}
	}

	.c-people__person-shape {
		border-radius: 8px;
		position: relative;
		overflow: hidden;

		&::before {
			content: "";
			position: absolute;
			z-index: 2;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(to top, rgba($black, 0.95) 0%, rgba($grey--darkest, 0) 30%);
			background: linear-gradient(to top, var(--primary-a) 0%, var(--primary-a) 10%, transparent 30%);
		}
	}

	.c-people__person-figure {
		@include figure;
	}

	.c-people__person-image {
		display: block;
		width: 100%;
		position: relative;
		z-index: 1;

		transform-origin: 50% 0;
		@include link-transition;
	}

	.c-people__person-body {
		position: absolute;
		z-index: 3;
		bottom: 0;
		left: 0;
		width: 100%;

		@include flex-extend;
		align-items: flex-end;
		padding: $bsu;
		color: $white;
		text-align: left;
	}

	.c-people__person-content {
		flex-grow: 1;
	}

	.c-people__person-name {
		display: block;
		margin-bottom: $ssu;

		@include font-heading-20b;
	}

	.c-people__person-title {
		display: block;

		@include font-base-16;
	}
	.c-people__person-location {
		display: none;
	}

	.c-people__person-icon {
		flex-shrink: 0;
		margin-left: $bsu;

		background-color: var(--primary-a);

		@include flex-extend;
		align-items: center;
		justify-content: center;
		width: 44px;
		height: 44px;
		border-radius: 4px;
		border: 1px solid currentColor;
		font-size: 16px;

		@include link-transition;
	}
}
