/* COMPONENT.HEADER-NOTIFICATION */

.c-header-notification {
	background: $brand;
	color: $white;
	padding: $ssu 0;

	text-align: center;
}

.c-header-notification__heading {
	margin-bottom: $ssu;
}

.c-header-notification__text {
	:last-child {
		margin-bottom: 0;
	}

	a {
		@include link-plain;
		text-decoration: underline;
	}
}
