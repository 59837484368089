// COMPONENT.HERO

@use "sass:math";

$hero-bp: "extra-large";

$hero__slide-body-width: 600px;

.c-hero {
	position: relative;
	margin-bottom: $lsu;
}

.c-hero__heading {
	position: absolute;
	top: calc(50% + 32px);
	left: 0;
	transform: translate(-50%, 0%);

	@include font-heading-28b;
	@include bp("medium") {
		@include font-heading-32b;
	}
	@include bp($hero-bp) {
		@include font-heading-45b;
	}
	color: $white;
	text-shadow: 0 0 5px $black;
}

.c-hero__heading-layout {
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
	transform: translateY(-50%);
}

.c-hero__heading-wrapper {
	@include layout-wrapper;
}

.c-hero__heading {
	position: static;
	transform: none;
}

.c-hero__button {
	@include bp-below("medium") {
		@include button("small");
	}
	@include bp("medium") {
		@include button("medium");
	}
	@include bp($hero-bp) {
		@include button("large");
	}

	display: inline-block;
	margin-right: $bsu;
	margin-bottom: $bsu;
}

/////////////
// PRIMARY //
/////////////

.c-hero__primary {
	margin: 0 0 $bsu 0;
	position: relative;
	z-index: 1;

	@include flex-extend;
	flex-direction: column-reverse;

	@include bp($hero-bp) {
		flex-direction: row;
		align-items: stretch;
	}
}

.c-hero__body {
	@include flex-extend;
	align-items: center;
	padding: $bsu;

	@include bp($hero-bp) {
		width: 100%;
		min-height: 200px;
		.c-hero--has-image & {
			width: 50%;
			min-height: 540px;
			padding-left: 0;
			padding-right: 0;
		}

		// Offset from image
		margin-bottom: $lsu;
	}
}

.c-hero__body-content {
	flex-direction: column;

	padding: $bsu;
	@include bp($hero-bp) {
		@include layout-wrapper;
		.c-hero--has-image & {
			max-width: none;
			padding: 0;

			@include bp-below($layout-wrapper-max-width) {
				padding-left: $bsu;
			}
			@include layout-split(left, 50%);
			padding-right: $lsu;
		}
	}
}

.c-hero__title {
	@include font-heading-28b;
	@include bp("medium") {
		@include font-heading-32b;
	}
	@include bp($hero-bp) {
		@include font-heading-36b;
	}
	position: relative;
	z-index: 2;
	margin-bottom: $bsu;

	@include bp($hero-bp) {
		font-size: 60px;
		line-height: 1;
		margin-bottom: $lsu;
	}
}

.c-hero__text {
	@include font-base-18;

	@include bp-below("medium") {
		display: none;
	}
	@include bp($hero-bp) {
		flex-grow: 1;
	}
}

.c-hero__date {
	@include font-base-12;
	margin-bottom: 2em;
}

.c-hero__scroll-icon {
	margin-top: $lsu;
	color: $red;
	text-align: center;
	font-size: 24px;
	display: none;

	@include bp($hero-bp) {
		display: block;
	}
}

.c-hero__figure {
	@include figure;

	flex-grow: 1;
	position: relative;
	overflow: hidden;
	max-height: 80vh;
	max-height: min(400px, 80vh);

	@include bp($hero-bp) {
		max-height: none;
	}

	.c-hero--image & {
		height: 300px;
	}
}

.c-hero__image {
	display: block;
	width: 100%;

	.c-hero--image & {
		height: 100%;
		padding: 0 !important;
		object-fit: cover;
	}
}

.c-hero__video {
	display: none;

	@include bp("medium") {
		display: block;
		width: 100%;
		max-height: 80vh;
		object-fit: cover;

		~ .c-hero__image {
			display: none;
		}
	}
}

///////////////
// SECONDARY //
///////////////
.c-hero__secondary {
	margin: ($bsu * 3) 0 0; // Match .o-layout-page
}

.c-hero__secondary-title {
	width: 100%;
	text-align: center;
}

.c-hero__secondary-title-text {
	display: block;
	color: $brand;
	margin-bottom: $bsu;

	@include bp($hero-bp) {
		padding: 0 20px;
		margin-bottom: 0;
	}
}

.c-hero__secondary-introduction {
	@include font-base-18;
	flex: 0 1 auto;

	@include bp($hero-bp) {
		margin-top: 4px; // Align better with secondary title
		margin-left: 40px;
	}
}

//////////////
// HOMEPAGE //
//////////////

.c-hero--homepage {
	.c-hero__primary {
		@include bp-below($hero-bp) {
			flex-direction: column;
		}
	}

	.c-hero__body {
		position: relative;
		&::after {
			content: "";
			position: absolute;
			right: -30px;
			bottom: 0;

			display: block;
			width: 196px;
			height: 100px;

			background: url("/content/images/interface/bg/triangle.svg") no-repeat bottom right;
			background-size: contain;
		}

		@include bp-below($hero-bp) {
			overflow: hidden;
			padding: $bsu $lsu $bsu $bsu;
		}
		@include bp($hero-bp) {
			margin-bottom: 160px;
			&::after {
				right: 0;
				bottom: -60px;
				width: 356px;
				height: 180px;
			}
		}
	}

	.c-hero__body-content {
		@include bp-below($hero-bp) {
			padding: 0;
		}
	}
}

.c-hero__slides {
	flex-grow: 1;
	position: relative;

	@include bp-below($hero-bp) {
		padding-top: 350px;
		padding-top: min(75%, 80vh);
	}
}

.c-hero__slide {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	.c-hero__figure {
		height: 100%;
		max-height: none;
	}

	visibility: hidden;
	opacity: 0;
	&[aria-current="true"] {
		visibility: visible;
		opacity: 1;
	}

	&.allow-animations {
		animation: 0.5s fadeout forwards,
			0.5s becomeHidden forwards;

		&[aria-current="true"] {
			animation: 0.5s fadein forwards,
				0.5s becomeVisible forwards;
		}
	}
}

.c-hero__slide-body {
	position: absolute;
	bottom: 0;
	left: 0;

	@supports (background-color: var(--supporting-1-a)) {
		--hero__slide-body-border-width: 8px;
		@include bp($hero-bp) {
			--hero__slide-body-border-width: 16px;
		}

		&::before {
			content: "";
			position: absolute;
			top: 0;
			height: 100%;

			left: calc(-1 * var(--hero__slide-body-border-width));
			width: var(--hero__slide-body-border-width);
			background-color: var(--supporting-1-a);
		}

		@include bp-below($hero-bp) {
			padding-left: calc(#{$bsu} + var(--hero__slide-body-border-width));
			&::before {
				left: 0;
			}
		}
	}

	padding: $bsu;
	@include bp($hero-bp) {
		padding: $lsu $lsu $bsu;
	}
	width: calc(100% - #{$lsu});
	@include bp("medium") {
		width: $hero__slide-body-width;
	}
}

.c-hero__slide-title {
	display: block;
	margin-bottom: $bsu;

	@include font-heading-18b;
	@include bp($hero-bp) {
		margin-bottom: $lsu + $ssu;
		@include font-heading-28b;
	}
}

.c-hero__slide-link {
	@include font-heading-14b;
	@include bp($hero-bp) {
		@include font-heading-16b;
	}

	display: inline-block;
	margin-right: 160px;

	padding-bottom: $ssu;
	@include link-fancy(before);
	color: inherit;

	&::after {
		display: inline-block;
		vertical-align: middle;
		margin-left: $ssu;

		@include font-icon;
		content: $iconf-arrow-right;
	}
}

.c-hero__slide-buttons {
	position: absolute;
	bottom: 0;
	right: $lsu + $ssu;
	@include bp("medium") {
		right: calc(100% - #{$hero__slide-body-width - $ssu});
	}

	display: flex;
}

.c-hero__slide-button {
	@include bp-below($hero-bp) {
		@include button("medium", true);
	}
	@include bp($hero-bp) {
		@include button("large", true);
	}
	background: transparent;

	margin-left: $tsu;
	&:first-child {
		margin-left: 0;
	}

	&::before {
		@include font-icon;
	}
}

.c-hero__slide-button--prev {
	&::before {
		content: $iconf-arrow-left;
	}
}

.c-hero__slide-button--next {
	&::before {
		content: $iconf-arrow-right;
	}
}
