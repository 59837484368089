﻿// MACRO.BLOCKQUOTE

.m-quote {
	@include body-text__figure;
	padding-left: $bsu + 4px;

	position: relative;
	&::before {
		content: "";

		position: absolute;
		top: 0;
		left: 0;
		width: 4px;
		height: 100%;
	}
}

.m-quote__text {
	@include font-heading-20b;
}

.m-quote__citation {
	@include font-base-16;
	font-style: normal;
}
