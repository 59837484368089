﻿/* PLUGINS.SS360 */


.c-ss360__search-form-wrap {
    display: flex;
    gap: $bsu;
    margin-bottom: $bsu;
    align-items: center;
    flex-direction: column;

    @include bp("medium") {
        flex-direction: row;
    }

    .c-page-header__heading {
        margin-bottom: 0;
    }
}


.c-ss360__search-from {
    max-width: 360px;
    margin-bottom: 0;
    border: 1px solid $grey;
}

.c-ss360__search-field {
    .c-ss360__search-from & {
        display: flex;
        flex-direction: row;
    }
}

.c-ss360__search-input {
    .c-ss360__search-from & {
        border: 0;
        display: block;
        padding: 10px 20px;
        border-radius: 2px;
        font-weight: 300;
        height: 53px;
        line-height: 53px;
        flex: 1;
    }
}

.c-ss360__search-btn {
    .c-ss360__search-from & {
        border: 0;
        background-color: transparent;
        color: $brand;
        padding: $bsu;
    }
}

.ss360-suggests  {
    .ss360-suggests__image-wrap {
        max-width: 100%;
        min-width: 200px;
        width: 100%;

        @include bp("medium") {
            width: 200px;
        }

        .ss360-suggests__image {
            max-height: 100%;
        }
    }

    .ss360-suggests__image-wrap--empty {
        display: none;
    }
}