﻿/* UTILITY.RESPONSIVE */

// Breakpoint u-hidden-- / u-show-- helpers
// Outputs a class to hide or show the element from a specific breakpoint
@each $breakpoint in $breakpoints {

	// Get the name of the breakpoint
	// Get the config sass-map
	// Get the suffix
	$alias: nth($breakpoint, 1);
	$config: nth($breakpoint, 2);
	$suffix: map-get($config, "suffix");

	@include bp($alias) {
		.u-hidden--#{$suffix} {
			display: none !important;
			visibility: hidden !important;
		}

		.u-show--#{$suffix} {
			display: block !important;
			visibility: visible !important;
		}
	}
}