﻿// BASE.TYPOGRAPHY

// Config
//-------
// Base settings all set on the HTML element
// $base-font-size is used throughout to calculate em/rem sizes
$base-font-family: "Proxima Nova", "proxima-nova", Arial, Helvetica, sans-serif;
$heading-font-family: $base-font-family;
$base-font-size: 16px;
$base-line-height: 22px;

$weight--light: 300;
$weight--normal: 400;
$weight--semibold: 600;
$weight--bold: 700;

// Proxima Nova
//
// URL: https://fonts.adobe.com/fonts/proxima-nova
//
// Licence URL: https://fonts.adobe.com/fonts/proxima-nova#licensing-section

@import "https://use.typekit.net/bjn6wrf.css";

// Adobe Fonts sets up these @font-face declarations.
// Because it uses $weight--semibold for semibold fonts,
// there may be issues in RTEs where fonts are told be be bold

/*
@font-face {
	font-family: "proxima-nova";
	font-weight: $weight--normal;
	font-style: normal;
	font-display: auto;
}

@font-face {
	font-family: "proxima-nova";
	font-weight: $weight--normal;
	font-style: italic;
	font-display: auto;
}

@font-face {
	font-family: "proxima-nova";
	font-weight: $weight--semibold;
	font-style: normal;
	font-display: auto;
}

@font-face {
	font-family: "proxima-nova";
	font-weight: $weight--semibold;
	font-style: italic;
	font-display: auto;
}
*/

// Using URLs from Adobe Fonts' CSS, set up @font-face declarations using the correct weights.
// In case these URLs may one day change, they're set up with a different name and
// Adobe Fonts' ones are used as a fallback

@font-face {
	font-family: "Proxima Nova";
	src: url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3") format("woff2"),
		url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3") format("woff"),
		url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3") format("opentype");
	font-weight: $weight--normal;
	font-style: normal;
	font-display: auto;
}

@font-face {
	font-family: "Proxima Nova";
	src: url("https://use.typekit.net/af/3322cc/00000000000000007735e616/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i4&v=3") format("woff2"),
		url("https://use.typekit.net/af/3322cc/00000000000000007735e616/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i4&v=3") format("woff"),
		url("https://use.typekit.net/af/3322cc/00000000000000007735e616/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i4&v=3") format("opentype");
	font-weight: $weight--normal;
	font-style: italic;
	font-display: auto;
}

@font-face {
	font-family: "Proxima Nova";
	src: url("https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n6&v=3") format("woff2"),
		url("https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n6&v=3") format("woff"),
		url("https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n6&v=3") format("opentype");
	font-weight: $weight--bold;
	font-style: normal;
	font-display: auto;
}

@font-face {
	font-family: "Proxima Nova";
	src: url("https://use.typekit.net/af/144da4/00000000000000007735e619/30/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i6&v=3") format("woff2"),
		url("https://use.typekit.net/af/144da4/00000000000000007735e619/30/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i6&v=3") format("woff"),
		url("https://use.typekit.net/af/144da4/00000000000000007735e619/30/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i6&v=3") format("opentype");
	font-weight: $weight--bold;
	font-style: italic;
	font-display: auto;
}

// Style declarations
//
//	.c-component__text {
//		@include font-base-12;
//	}

@mixin font-body-text {
	font-family: $base-font-family;
	font-size: 16px;
	font-weight: $weight--normal;
	line-height: lh(26px, 15px);
}

////////////////////////
// Base font variants //
////////////////////////

@mixin font-base-28b {
	font-family: $base-font-family;
	font-size: 28px;
	font-weight: $weight--bold;
	line-height: lh(36, 28);
}

@mixin font-base-24b {
	font-family: $base-font-family;
	font-size: 24px;
	font-weight: $weight--bold;
	line-height: 1.15;
}

@mixin font-base-20 {
	font-family: $base-font-family;
	font-size: 20px;
	font-weight: $weight--normal;
	line-height: 1.15;
}

@mixin font-base-20-tall {
	font-family: $base-font-family;
	font-size: 20px;
	font-weight: $weight--normal;
	line-height: 1.4;
	letter-spacing: 0.01em;
}

@mixin font-base-20b {
	font-family: $base-font-family;
	font-size: 20px;
	font-weight: $weight--bold;
	line-height: lh(28, 20);
}

@mixin font-base-18 {
	font-family: $base-font-family;
	font-size: 18px;
	font-weight: $weight--normal;
	line-height: 1.35;
}

@mixin font-base-18b {
	font-family: $base-font-family;
	font-size: 18px;
	font-weight: $weight--bold;
	line-height: 1.35;
}

@mixin font-base-16 {
	font-family: $base-font-family;
	font-size: 16px;
	font-weight: $weight--normal;
	line-height: 1.5;
	letter-spacing: 0.01em;
}

@mixin font-base-16b {
	font-family: $base-font-family;
	font-size: 16px;
	font-weight: $weight--bold;
	line-height: 1.5;
	letter-spacing: 0.01em;
}

@mixin font-base-14 {
	font-family: $base-font-family;
	font-size: 14px;
	font-weight: $weight--normal;
	line-height: 1.5;
}

@mixin font-base-14b {
	font-family: $base-font-family;
	font-size: 14px;
	font-weight: $weight--bold;
	line-height: 1.5;
}

@mixin font-base-14u {
	font-family: $base-font-family;
	font-size: 14px;
	font-weight: $weight--normal;
	text-transform: uppercase;
	line-height: 1.15;
}

@mixin font-base-12 {
	font-family: $base-font-family;
	font-size: 12px;
	font-weight: $weight--normal;
	line-height: lh(17, 12);
}

@mixin font-base-12b {
	font-family: $base-font-family;
	font-size: 12px;
	font-weight: $weight--bold;
	line-height: lh(17, 12);
}

@mixin font-base-12bu {
	@include font-base-12b;
	text-transform: uppercase;
}

@mixin font-base-10b {
	font-family: $base-font-family;
	font-size: 10px;
	font-weight: $weight--bold;
	line-height: lh(14, 10);
}

@mixin font-base-10 {
	font-family: $base-font-family;
	font-size: 10px;
	font-weight: $weight--bold;
	line-height: lh(14, 10);
}

///////////////////////////
// Heading font variants //
///////////////////////////

@mixin font-heading-45b {
	font-family: $heading-font-family;
	font-size: 45px;
	font-weight: $weight--bold;
	line-height: lh(52, 45);
}

@mixin font-heading-36b {
	font-family: $heading-font-family;
	font-size: 36px;
	font-weight: $weight--bold;
	line-height: 1.15;
}

@mixin font-heading-32 {
	font-family: $heading-font-family;
	font-size: 32px;
	font-weight: $weight--normal;
	line-height: 1.15;
}

@mixin font-heading-32b {
	font-family: $heading-font-family;
	font-size: 32px;
	font-weight: $weight--bold;
	line-height: 1.15;
}

@mixin font-heading-28b {
	font-family: $heading-font-family;
	font-size: 28px;
	font-weight: $weight--bold;
	line-height: lh(36, 28);
}

@mixin font-heading-24b {
	font-family: $heading-font-family;
	font-size: 24px;
	font-weight: $weight--bold;
	line-height: 1.15;
}

@mixin font-heading-22b {
	font-family: $heading-font-family;
	font-size: 22px;
	font-weight: $weight--bold;
	line-height: 1.15;
}

@mixin font-heading-20 {
	font-family: $heading-font-family;
	font-size: 20px;
	font-weight: $weight--normal;
	line-height: 1.15;
}

@mixin font-heading-20-tall {
	font-family: $heading-font-family;
	font-size: 20px;
	font-weight: $weight--normal;
	line-height: 1.4;
	letter-spacing: 0.01em;
}

@mixin font-heading-20b {
	font-family: $heading-font-family;
	font-size: 20px;
	font-weight: $weight--bold;
	line-height: 1.15;
}

@mixin font-heading-20bu {
	@include font-heading-20b;
	text-transform: uppercase;
}

@mixin font-heading-18b {
	font-family: $heading-font-family;
	font-size: 18px;
	font-weight: $weight--bold;
	line-height: 1.35;
}

@mixin font-heading-16b {
	font-family: $heading-font-family;
	font-size: 16px;
	font-weight: $weight--bold;
	line-height: lh(18, 16);
}

@mixin font-heading-16bu {
	@include font-heading-16b;
	text-transform: uppercase;
}

@mixin font-heading-14b {
	font-family: $heading-font-family;
	font-size: 14px;
	font-weight: $weight--bold;
	line-height: 1.5;
}

@mixin font-heading-14 {
	font-family: $heading-font-family;
	font-size: 14px;
	font-weight: $weight--normal;
	line-height: 1.5;
}

@mixin font-heading-12 {
	font-family: $heading-font-family;
	font-size: 12px;
	font-weight: $weight--normal;
	line-height: lh(14, 12);
}

@mixin font-heading-12b {
	font-family: $heading-font-family;
	font-size: 12px;
	font-weight: $weight--bold;
	line-height: lh(14, 12);
}

html {
	font-family: $base-font-family;
	color: $body-color;
}

p {
	margin-top: 0;
}
