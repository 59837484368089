// COMPONENT.BASIC-FILTER

@use "sass:math";

.c-basic-filter {
	@include list-reset;

	@include flex-extend;
	justify-content: center;

	margin: ($bsu - $tsu) 0;
}

.c-basic-filter__item {
	padding: $tsu;
}
