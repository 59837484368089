﻿// COMPONENT.VIDEO-GALLERY

@use "sass:math";

.c-video-gallery {}

.c-video-gallery__screen {
	padding-bottom: percentage(math.div(9, 16));
}

.c-video-gallery__screen-poster {
	display: block;
	position: relative;

	&.is-playing {
		display: none;
	}
}

.c-video-gallery__screen-poster-icon {
	@include abs-center;
}

.c-video-gallery__screen-embed {}

.c-video-gallery__thumbnail-list {
	@include list-reset;

	@include flex-extend;
	margin-left: -$bsu;
}

.c-video-gallery__thumbnail {
	padding-left: $bsu;
	padding-top: $bsu;

	flex: 0 1 auto;
	width: 25%;
}

.c-video-gallery__thumbnail-link {
	display: block;
	position: relative;
	&::before {
		content: "";

		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		background: rgba($black, 0.2);

		pointer-events: none;

		@include link-transition(background);
	}

	&:hover,
	&:focus,
	&[aria-current="true"] {
		&::before {
			background: rgba($black, 0);
		}
	}
}

.c-video-gallery__thumbnail-image {
	display: block;
	max-width: 100%;
}

.c-video-gallery__thumbnail-icon {
	@include abs-center;
}
