// CONTROL.HEADING-AND-TEXT

$heading-and-text-bp: $layout-page-bp;

.c-heading-and-text {
	@include control;
}

.c-heading-and-text__heading {
	display: block;
	margin-bottom: $bsu;

	@include bp-below($heading-and-text-bp) {
		@include font-heading-18b;
	}
	@include bp($heading-and-text-bp) {
		@include font-heading-20b;
	}
}

.c-heading-and-text__text {
	display: block;
	padding: $tsu 0;

	@include body-text;

	a {
		color: inherit;
		color: var(--primary-a);

		// Doesn't work on wrapping links
		// @include link-fancy;
		&:hover,
		&:focus {
			text-decoration: underline;
			text-decoration-color: var(--secondary-a);
		}
	}

	@supports (background-color: var(--supporting-1-a)) {
		padding-left: $bsu;
		position: relative;
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 4px;

			background: var(--supporting-1-a);
		}
	}
}
