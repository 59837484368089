﻿// UTILITY.PADDING

.u-p--0 {
	padding: 0 !important;
}

.u-pt--0 {
	padding-top: 0 !important;
}

.u-pr--0 {
	padding-right: 0 !important;
}

.u-pb--0 {
	padding-bottom: 0 !important;
}

.u-pl--0 {
	padding-left: 0 !important;
}

.u-ph--0 {
	padding-right: 0 !important;
	padding-left: 0 !important;
}

.u-pv--0 {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}
