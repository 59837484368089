// CONTROL.PEOPLE--ASIDE

@use "sass:math";

.c-people--aside {
	@include control;

	.c-people__layout {
		@include layout-wrapper;
	}

	.c-people__head {}

	.c-people__heading {
		display: block;
		margin-bottom: $bsu;

		@include font-heading-20b;
		color: var(--secondary-a);
	}

	.c-people__introduction {
		@include body-text;

		display: block;
		margin-bottom: $bsu;

		@include bp($layout-wrapper-max-width) {
			flex-shrink: 1;
		}
	}

	.c-people__list {
		@include list-reset;
		@include flex-extend;
		flex-direction: column;
	}

	.c-people__item {
		padding-top: $ssu;
		&:first-child {
			padding-top: 0;
		}

		width: percentage(math.div(1, 1));
	}

	@include bp("medium") {
		@include bp-below($layout-page-bp) {
			.c-people__list {
				flex-direction: row;
				flex-wrap: wrap;

				margin-left: -$ssu;
				margin-top: -$ssu;
			}

			.c-people__item {
				padding-left: $ssu;
				&, &:first-child {
					padding-top: $ssu;
				}

				width: percentage(math.div(1, 2));
			}
		}
	}


	// Person
	.c-people__person {
		@include button-reset;
		display: block;
		width: 100%;

		border-radius: 4px;
		overflow: hidden;
		background-color: $cyan--light;
		color: $body-color;
		@include link-transition(background-color);

		position: relative;
		isolation: isolate;
		&:hover,
		&:focus {
			background: var(--secondary-a);
			color: var(--secondary-b);
			.c-people__person-name,
			.c-people__person-title,
			.c-people__person-location,
			.c-people__person-icon {
				color: inherit;
			}

			.c-people__person-image {
				transform: scale(1.1);
			}
		}
	}

	.c-people__person-shape {
		display: flex;
	}

	.c-people__person-figure {
		@include figure;

		width: 100px;
		flex-shrink: 0;
	}

	.c-people__person-image {
		display: block;
		position: relative;
		z-index: 1;

		transform-origin: 50% 0;
		@include link-transition;
	}

	.c-people__person-body {
		position: relative;
		z-index: 3;
		bottom: 0;
		left: 0;
		width: 100%;

		@include flex-extend;
		align-items: flex-start;
		padding: $bsu;
		text-align: left;
	}

	.c-people__person-content {
		flex-grow: 1;
	}

	.c-people__person-name {
		display: block;
		margin-bottom: $ssu;

		@include font-heading-16b;
		color: var(--primary-a);

		@include link-transition(color);
	}

	.c-people__person-title,
	.c-people__person-location {
		display: block;

		color: var(--primary-a);
		@include link-transition(color);
	}

	.c-people__person-title {
		@include font-base-14b;
	}

	.c-people__person-location {
		@include font-base-14;
	}

	.c-people__person-icon {
		position: absolute;
		bottom: $ssu;
		right: $ssu;

		color: var(--primary-a);
		@include link-transition(color);

		@include flex-extend;
		align-items: center;
		justify-content: center;
		width: 44px;
		height: 44px;
		font-size: 16px;
	}
}
