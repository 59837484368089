/* TOOLS.FUNCTIONS */

@use "sass:math";

// Line Height calculation
// Calculates a unitless line-height (See here on why unitless line-heights are good http://meyerweb.com/eric/thoughts/2006/02/08/unitless-line-heights/)
// $amount: Line height to return
// $context: By default it uses the default font-size. You can also pass it a context to avoid problems of compound font sizes.
// Example 1: lh(20)
// return: 1.25 depending on a root font-size of 16px
// Example 2: lh(24, 20)
// return: 1.2 depending on a contextual font-size of 20px
@function lh( $amount: 1, $context: $base-font-size ) {
	@return math.div($amount, $context);
}

// Returns a rem value according to the target. $base-font-size is the font-size of the root (html) node
// $target: font-size to return
// Example: rem(20)
// return: 1.25rem depending on a root font-size of 16px
@function rem( $target ) {
	@if $target == 0 { @return 0; }

	@return math.div($target, $base-font-size) + 0rem;
}

// Returns an em value according to the target.
// $target: font-size to return
// $context: By default it uses the default font-size. You can also pass it a context to avoid problems of compound font sizes.
// Example 1: em(20)
// return: 1.25em depending on a root font-size of 16px
// Example 2: em(20, 24)
// return: 0.8333333em depending on a context font-size of 24px
@function em( $target, $context: $base-font-size ) {
	@if $target == 0 { @return 0; }

	@return math.div($target, $context) + 0em;
}

// Retrieve value from breakpoint config map
// $bp: breakpoint list
// $key: string value of key to return
@function bp-config-value($breakpoint, $value) {
	$config: nth($breakpoint, 2);

	@if (map-has-key($config, $value)) {
		@return map-get($config, $value);
	} @else {
		@warn "Unfortunately, no value could be retrieved for '#{$value}'. Please make sure it is a key defined in '$breakpoints' config map";
	}
}