// Use the JobAdder static ID to increase specificity for overriding
#ja-jobs-widget {
	.ja-field {
		select {
			@include form-input;

			@include bp("small") {
				// This is what JobAdder sets, but form-input overrides it with 100%
				width: 215px;
			}
			height: auto;
		}
	}
	@include bp-below("small") {
		.ja-field-container,
		.ja-field,
		.ja-field select {
			box-sizing: border-box;
			width: 100%;
		}
	}

	.ja-button {
		@include button("medium");
		@extend .t-action--primary;
	}

	.ja-form,
	.job,
	.ja-job-details {
		margin-left: 0;
		margin-right: 0;

		padding-left: 0;
		padding-right: 0;
	}
}
