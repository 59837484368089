﻿// COMPONENT.BREADCRUMB

.c-breadcrumb {
	@include layout-wrapper;
	margin-top: $lsu;
	margin-bottom: $bsu;
}

.c-breadcrumb__list {
	@include list-reset;
	@include list-inline;

	> [data-divider]::after {
		margin: 0 $ssu;
		color: $grey;
	}
}

.c-breadcrumb__link {
	@include font-base-10;
	&:hover,
	&:focus {
		text-decoration: underline;
	}
}

.c-breadcrumb__current {
	@include font-base-10;
	@include link-plain;
	&:hover,
	&:focus {
		text-decoration: underline;
	}
}