// COMPONENT.NAVIGATION

// Main Navigation
$navigation-bg: $white;

$navigation-bp: $header-bp;
$navigation-bp--large: $header-bp--large;

.c-navigation {
	@include bp-below($navigation-bp) {
		display: none;
		width: 100%;
		background-color: $navigation-bg;

		&[aria-expanded="true"] {
			display: block;
			position: absolute;
			top: $header-height;
			top: var(--header-height);
			left: 0;
			height: calc(100vh - #{$header-height});
			height: calc(100vh - var(--header-height));
			overflow: auto;
		}
	}

	@include bp($navigation-bp) {
		align-self: stretch;
		display: flex;
		align-items: stretch;
	}
}

.c-navigation__items {
	border-top: 1px solid $grey;
	padding-top: $tsu;

	@include bp($navigation-bp) {
		border-top: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: stretch;
		padding: 0;
	}
}

.c-navigation__item {
	border-bottom: 1px solid $grey;

	@include bp($navigation-bp) {
		position: relative;
		border: 0;
		text-align: center;
		vertical-align: bottom;

		display: flex;
		align-items: stretch;
	}

	&.c-navigation__item--megamenu {
		@include bp($navigation-bp) {
			position: static;
		}
	}

	@include bp-below($navigation-bp) {
		&[aria-current="true"] {
			.c-navigation__link {
				position: relative;
				&::before {
					content: "";
					position: absolute;
					top: 0;
					left: 0;

					height: 100%;
					width: 7px;

					background: var(--supporting-1-a);
				}
			}
		}
	}
}

.c-navigation__item--home {
	@include bp($navigation-bp) {
		display: none;
	}
}

.c-navigation__item--secondary {
	@include bp($navigation-bp) {
		display: none;
	}
}

.c-navigation__link {
	@include font-heading-16b;
	display: block;
	padding: $bsu $hsu $bsu $bsu;

	position: relative;

	@include bp($navigation-bp) {
		display: flex;
		align-items: center;

		// Unequal top and bottom padding to align with logo
		padding: ($ssu + 5px) ($bsu - $tsu) $ssu;
		@include bp("extra-large") {
			padding: ($ssu + 10px) ($bsu - $tsu) $ssu;
		}
		white-space: nowrap;

		@include link-fancy;
		&::after {
			left: $ssu;
			bottom: $ssu;
		}
		&:hover,
		&:focus-visible,
		.c-navigation__item[aria-current="true"] &,
		.c-navigation__item[aria-expanded="true"] & {
			&::after {
				width: calc(100% - #{$ssu * 2});
				opacity: 1;
			}
		}
	}
	@include bp("extra-large") {
		&::after {
			bottom: $bsu;
		}
	}
}

.c-navigation__link--home {
	font-size: rem(20px);

	@include bp($navigation-bp) {
		line-height: 1;
	}
}

.c-navigation__expander {
	@include bp-below($navigation-bp) {
		position: absolute;
		right: $bsu * 1.5;
		top: 50%;
		transform: translateY(-50%);
	}
	@include bp($navigation-bp) {
		margin-left: $tsu;
	}

	&::before {
		display: block;

		@include font-icon;

		@include bp-below($navigation-bp) {
			content: $iconf-arrow-right;

			.c-navigation__item[aria-expanded="true"] & {
				transform: rotate(90deg);
			}
		}
		@include bp($navigation-bp) {
			content: $iconf-arrow-down-chevron;

			font-size: 0.7em;
			.c-navigation__item[aria-expanded="true"] & {
				transform: rotate(-180deg);
			}
		}

		@include link-transition(transform);
		transform-origin: 50% 50%;
	}
}

// Navigation Drop Down
.c-navigation-dropdown__items {
	display: none;
	padding: $ssu 0 $ssu $bsu;
	width: 100%;
	background: $navigation-bg;

	@include bp($navigation-bp) {
		position: absolute;
		padding: 0;
		min-width: 250px;
		left: 50%;
		top: 100%;
		transform: translateX(-50%);
		box-shadow: 0 0 10px 0 rgba($black, 0.2);
	}

	[aria-expanded="true"] > &,
	.no-js :hover > & {
		display: block;
	}
}

.c-navigation-dropdown__item {
	display: inline-block;
	width: 100%;
	margin-bottom: $ssu;

	@include bp($navigation-bp) {
		@include ui-border("bottom");
		margin: 0;
	}
}

.c-navigation-dropdown__link {
	@include font-base-14b;
	display: block;
	padding: $tsu 0;
	color: $body-color;

	&::before {
		display: none;
	}

	@include bp($navigation-bp) {
		position: relative;
		padding: $ssu $lsu $ssu $bsu;

		&:hover {
			text-decoration: underline;
		}
	}
}

.c-navigation__footer {
	background: $grey--lightest;
	padding: $lsu $bsu;

	@include bp($navigation-bp) {
		display: none;
	}
}

.c-navigation__social-list {
	@include list-reset;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	margin-left: -$bsu;
	margin-top: -$bsu;
}

.c-navigation__social-item {
	padding-left: $bsu;
	padding-top: $bsu;
}

.c-navigation__social-link {
	@include circle(40px);
	@include link-transition;
	font-size: 20px;
}
