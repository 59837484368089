﻿// CONTROL.ICON-PROMOS

.c-icon-promos__item {
	text-align: center;
	padding: $bsu;
}

.c-icon-promos__item-icon {
	display: block;
	position: relative;
	margin: 0 auto;
	font-size: 40px;
	width: 100px;
	height: 100px;
	border: 1px solid $black;
	border-radius: 50%;

	&:before {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
}

.c-icon-promos__item-title {
	@include font-heading-24b;
	margin-bottom: 0.7em;
	margin-top: 1em;
}

.c-icon-promos__item-abstract {
	@include font-base-14;
}