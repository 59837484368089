﻿// THEME

$white: #fff;
$black: #000;

$grey--lightest: #f9f9f9;
$grey--light: #efefef;
$grey: #d0d0d0;
$grey--dark: #666;
$grey--darkest: #333;

$blue: #00b3c4;

$red: #e73431;
$red--dark: #780c15;

$brand: #003366;
$brand--alt: #00afb3;

$cyan--light: #f2fbfb;

// Shared non-theme colours, e.g. alert, errors
$success: #008000;
$success--light: lighten($success, 40);
$warning: #ffa500;
$warning--light: lighten($warning, 40);
$error: #f00;
$error--light: lighten($error, 40);

// Assign colours to common variables
$body-color: $grey--darkest;
$base-ui-color: $grey--light;

// Main Colours
$primary-a: $brand !default;
$primary-b: $white !default;
$secondary-a: $brand--alt !default;
$secondary-b: $white !default;

$supporting-1-a: #64d233;
$supporting-1-b: #ffffff;

$supporting-2-a: #002953;
$supporting-2-b: #ffffff;

$link: $brand !default;
$link-hover: $brand--alt !default;
