﻿// CONTROL.CTA

.c-cta__body {
	position: relative;
	padding: $bsu;
	margin: $bsu 0;

	color: $body-color;
	background-color: $grey--lightest;

	border-radius: 4px;

	&::before {
		@include pseudo-element;
		top: 0;
		left: $bsu;
		width: 100px;
		height: 5px;
	}

	> *:last-child {
		margin-bottom: 0;
		margin-right: 0;
	}

	@include bp("medium") {
		display: flex;
		align-items: center;

		padding-left: $lsu;
		padding-right: $lsu;
		&::before {
			left: $lsu;
		}

		.o-layout-main & {
			@include flex-extend;
			padding: ($bsu * 1.5) ($bsu * 2);
			align-items: center;
			text-align: left;

			&::before {
				right: auto;
				left: ($bsu * 2);
				transform: translateX(0);
			}
		}
	}

	@include bp("large") {
		padding: $lsu $hsu;
		&::before {
			left: $hsu;
		}

		.o-layout-main & {
			@include flex-extend;
			padding: ($bsu * 1.5) ($bsu * 4);
			align-items: center;
			text-align: left;

			&::before {
				left: ($bsu * 4);
			}
		}
	}
}

.c-cta__body-wrapper {
	margin-bottom: $bsu;

	@include bp("medium") {
		flex-grow: 1;
		margin-bottom: 0;

		.o-layout-main & {
			flex: 1 1 auto;
			padding-right: $lsu;
			margin-bottom: 0;
		}
	}

	> *:last-child {
		margin-bottom: 0;
	}
}

.c-cta__heading {
	@include font-base-20b;
	margin-bottom: 0.5em;

	@include bp("large") {
		@include font-base-24b;
	}
}

.c-cta__text {
	@include font-body-text;
	margin-bottom: 2em;
}

.c-cta__link {
	@include button("medium");

	@include bp("medium") {
		@include button-size("large");
		margin-left: $bsu;
		white-space: nowrap;
	}
}