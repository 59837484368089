﻿// BASE.FORMS

fieldset {
	padding: 0;
	border: 0;
	margin: 0;
}

label[for] {
	cursor: pointer;
}
