﻿// COMPONENT.THEME

// !IMPORTANT!
// Any changes made to this file should also be made in
// ~/Views/Housing/ThemeStyle.cshtml


// These custom properties can be used directly in SCSS files for
// progressive enhancement, where a utility class won't work.

// Browsers that don't support custom properties will ignore CSS like this:

// background-color: var(--primary-a);

// So if a fallback is necessary you can use something like this:
// background-color: $grey;
// background-color: var(--primary-a);

// Browser support:
// https://caniuse.com/#search=custom%20properties
:root {
	--primary-a: #{$primary-a};
	--primary-b: #{$primary-b};
	--secondary-a: #{$secondary-a};
	--secondary-b: #{$secondary-b};
	--supporting-1-a: #{$supporting-1-a};
	--supporting-1-b: #{$supporting-1-b};
	--supporting-2-a: #{$supporting-2-a};
	--supporting-2-b: #{$supporting-2-b};
	--link: #{$link};
	--link--hover: #{$link-hover};
}

.t-bg,
.o-fit {
	background-color: $primary-a;
}

.t-bg--rev {
	background-color: $primary-b;
}

.t-bg--sec {
	background-color: $secondary-a;
}

.t-bg__text {
	color: $primary-b;
}

.t-text,
.t-bg__text--rev {
	color: $primary-a;
}

.t-text--sec,
.o-control__heading {
	color: $secondary-a;
}

.t-bg__text--sec {
	color: $secondary-b;
}

.t-bg--sup-1 {
	background-color: $supporting-1-a;
}

.t-bg__text--sup-1 {
	color: $supporting-1-b;
}

.t-bg--rev--active:hover,
.t-bg--rev--active:focus {
	background-color: $primary-b;
}

.t-bg__text--rev--active:hover,
.t-bg__text--rev--active:focus,
.t-hover-parent:hover .t-bg__text--rev--active,
.t-hover-parent:focus .t-bg__text--rev--active {
	color: $secondary-a;
}

.t-bg--active:hover,
.t-bg--active:focus,
.t-hover-parent:hover .t-bg--active,
.t-hover-parent:focus .t-bg--active {
	background: $secondary-a;
}

.t-bg__text--active:hover,
.t-bg__text--active:focus,
.t-hover-parent:hover .t-bg__text--active,
.t-hover-parent:focus .t-bg__text--active {
	color: $secondary-b;
}

.t-text--sec--active:hover,
.t-text--sec--active:focus,
.t-hover-parent:hover .t-text--sec--active,
.t-hover-parent:focus .t-text--sec--active {
	color: $secondary-a;
}

.t-before-bg::before {
	background-color: $primary-a;
}

.t-before-bg--sec::before {
	background-color: $secondary-a;
}

.t-before-bg--sup-1::before {
	background-color: $supporting-1-a;
}

.t-before-text::before {
	color: $primary-a;
}

.t-after-bg::after,
.t-after-bg.is-selected::after {
	background-color: $primary-b;
}

.t-border-hover:focus,
.t-border-hover:hover,
.t-hover-parent:focus .t-border-hover,
.t-hover-parent:hover .t-border-hover {
	border-color: $primary-a;
}

.t-border-hover[aria-expanded="false"]:hover {
	border-color: $primary-a;
}

.t-link,
.t-link-parent a,
.o-body-text a,
.o-layout-header__lede a {
	color: $link;
}

.t-link:focus,
.t-link:hover,
.t-hover-parent:hover .t-link,
.t-hover-parent:focus .t-link,
.t-link-parent a:focus,
.t-link-parent a:hover,
.o-body-text a:focus,
.o-body-text a:hover,
.o-layout-header__lede a:focus,
.o-layout-header__lede a:hover {
	color: $link-hover;
	text-decoration: underline;
}

.t-link--primary-hover:focus,
.t-link--primary-hover:hover,
.t-hover-parent:focus .t-link--primary-hover,
.t-hover-parent:hover .t-link--primary-hover {
	color: $primary-a;
}

.t-link-parent--plain a {
	color: $primary-b;
}

.t-link-parent--plain a:hover,
.t-link-parent--plain a:focus {
	text-decoration: underline;
}

.t-link-parent--sec-plain a {
	color: $secondary-b;
}

.t-link-parent--sec-plain a:hover,
.t-link-parent--sec-plain a:focus {
	text-decoration: underline;
}

.t-link-parent--alt a {
	color: $primary-a;
}

/* Plugins - we don't want to change the class names */
.flickity-prev-next-button {
	background-color: $primary-a;
}

.flickity-button-icon {
	fill: $primary-b;
}

/***********/
/* Actions */
/***********/
/* primary */
.t-action--primary,
.umbraco-forms-form .primary {
	background-color: $primary-a;
	color: $primary-b;
}

.t-action--primary:hover,
.t-action--primary:focus,
.umbraco-forms-form .primary:hover,
.umbraco-forms-form .primary:focus,
.t-hover-parent:hover .t-action--primary,
.t-hover-parent:focus .t-action--primary {
	background-color: $secondary-a;
	color: $secondary-b;
}

/* secondary */
.t-action--secondary {
	background-color: transparent;
	color: $primary-b;
	box-shadow: inset 0 0 0 1px $primary-b;
}
.t-action--secondary:hover,
.t-action--secondary:focus,
.t-hover-parent:hover .t-action--secondary,
.t-hover-parent:focus .t-action--secondary {
	background-color: $secondary-a;
	color: $secondary-b;
	box-shadow: inset 0 0 0 1px $secondary-a;
}

/* tertiary */
.t-action--tertiary {
	background-color: $secondary-a;
	color: $secondary-b;
}

.t-action--tertiary:hover,
.t-action--tertiary:focus,
.t-hover-parent:hover .t-action--tertiary,
.t-hover-parent:focus .t-action--tertiary {
	background-color: $primary-b;
	color: $primary-a;
	box-shadow: inset 0 0 0 1px $primary-a;
}

/* outline */
.t-action--outline {
	background-color: $primary-b;
	color: $primary-a;
	box-shadow: inset 0 0 0 1px $primary-a;
}

.t-action--outline[aria-current]:not([aria-current="false"]) {
	background-color: $primary-a;
	color: $primary-b;
	box-shadow: inset 0 0 0 1px $primary-a;
}

.t-action--outline:hover,
.t-action--outline:focus,
.t-hover-parent:hover .t-action--outline,
.t-hover-parent:focus .t-action--outline {
	background-color: $secondary-a;
	color: $secondary-b;
	box-shadow: inset 0 0 0 1px $secondary-a;
}


// Code below this point doesn't need to be duplicated in ThemeStyle.cshtml
.t-action--primary,
.t-action--secondary,
.t-action--outline {
	@include link-transition;
}
