// OBJECT.BODY-TEXT

// First class here targets the RTE within the CMS so it will also get styled
.mce-content-body {
	padding: 10px !important;
}

@mixin body-text {
	@include clearfix;

	& + & {
		margin-top: $bsu;
	}

	> *:first-child {
		margin-top: 0;
	}

	> *:last-child {
		margin-bottom: 0;
	}

	h2,
	h3,
	h4,
	h5 {
		margin: 1.2em 0 0.6em;
		color: var(--secondary-a);
	}

	h2 {
		@include font-heading-32b;
	}

	h3 {
		@include font-heading-24b;
	}

	h4 {
		@include font-heading-20b;
	}

	h5 {
		@include font-heading-18b;
	}

	> p {
		@include font-body-text;
		margin-bottom: 1.5em;
	}

	small {
		display: block;
		@include font-base-12;
	}

	hr {
		border-width: 1px 0 0;
		border-top: 1px solid $base-ui-color;
		height: 0;
		clear: both;
		margin: 1.5em 0;
	}

	> ul,
	> ol {
		padding: 0 0 0 1em;
		margin: 1.5em 0;

		li {
			@include font-body-text;
			margin-bottom: 1em;

			&::marker {
				color: $brand--alt;
				color: var(--secondary-a, $brand--alt);
			}
		}
		&[style*="list-style-type:"] {
			> li {
				list-style-type: inherit;
			}
		}

		ul,
		ol {
			margin: 0.25em 0;
		}

		ul {
			li {
				list-style-type: circle;
			}
			&[style*="list-style-type:"] li {
				list-style-type: inherit;
			}
		}
		li li ul {
			li {
				list-style-type: square;
			}
			&[style*="list-style-type:"] li {
				list-style-type: inherit;
			}
		}

		ol {
			li {
				list-style-type: lower-alpha;
			}
			&[style*="list-style-type:"] li {
				list-style-type: inherit;
			}
		}
		li li ol {
			li {
				list-style-type: lower-roman;
			}
			&[style*="list-style-type:"] li {
				list-style-type: inherit;
			}
		}
	}

	table {
		width: 100%;
		min-width: 480px;
		clear: both;
		margin-bottom: $bsu;
		border-collapse: collapse;

		p {
			margin: 0;
		}
		// Remove default min-width if content editor has set the width in the CMS
		&[style*="width"] {
			min-width: 0;
		}
		// Add margin to right if content editor has set the table to float left in the CMS
		&[style*="float: left"] {
			margin-right: $bsu;
		}
		// Add margin to left if content editor has set the table to float right in the CMS
		&[style*="float: right"] {
			margin-left: $bsu;
		}
	}

	.tablescroll table {
		margin-bottom: 0;
	}

	th,
	thead td {
		@include font-base-14b;
		background-color: $grey--lightest;
	}

	th,
	td {
		@include ui-border("all");
		padding: $ssu $bsu;
	}

	td {
		@include font-base-14;
		vertical-align: top;
	}

	tbody tr:nth-child(2n) {
		background-color: $cyan--light;
	}

	caption {
		@include ui-border(bottom);
		@include font-base-12;
		caption-side: bottom;
		padding: $bsu 0;
		text-align: left;
	}

	// Theme support
	@supports(background-color: var(--secondary-a)) {
		th,
		thead td {
			color: var(--secondary-b);
			background-color: var(--secondary-a);
		}
	}
}

@mixin body-text__figure {
	margin: $lsu auto;
	clear: both;

	&.u-float--left {
		clear: left;

		@include bp("small") {
			margin: 0 $lsu $bsu 0;
		}
	}

	&.u-float--right {
		clear: right;

		@include bp("small") {
			margin: 0 0 $bsu $lsu;
		}
	}
}

@mixin body-text__figcaption {
	@include ui-border("bottom");
	@include font-base-12;
	padding: $bsu 0
}

.mce-content-body,
.o-body-text {
	@include body-text;
}

// RTE figure - shared styling across macros
.o-body-text__figure {
	@include body-text__figure;
}

.o-body-text__figcaption {
	@include body-text__figcaption;
}
