// COMPONENT.HEADER

@use "sass:math";

$header-bp: "large";
$header-bp--large: "extra-large";

$header-height: 100px;

:root {
	--header-height: 65px;
}
@include bp($header-bp--large) {
	:root {
		--header-height: 100px;
	}
}

.c-header {
	position: absolute;
	z-index: $z-header;
	height: $header-height;
	height: var(--header-height);
	width: 100%;
	background-color: $white;
	box-shadow: 0 8px 6px -6px rgba($black, 0.2);
	transition: all 0.25s ease-in-out;

	&.is-sticky {
		position: fixed;
		top: 0;

		&.is-up {
			transform: translateY(calc(-100% - 6px));
			&.is-open {
				transform: none;
			}
		}
	}
}

.c-header__spacer {
	height: $header-height;
	height: var(--header-height);
}

.c-header__wrapper {
	@include layout-wrapper;
	@include flex-extend;

	align-items: center;
	justify-content: space-between;
}

.c-header__logo {
	$header-logo-height: 22px;
	$header-logo-padding: math.div($header-height - $header-logo-height, 2);

	--header-logo-height: 22px;
	@include bp-below($header-bp--large) {
		--header-logo-height: 16px;
	}
	--header-logo-padding: calc((var(--header-height) - var(--header-logo-height)) / 2);

	display: inline-block;
	padding: $header-logo-padding $bsu $header-logo-padding 0;
	padding: var(--header-logo-padding) $bsu var(--header-logo-padding) 0;

	text-align: center;
	height: $header-height;
	height: var(--header-height);

	img {
		height: 100%;
		object-fit: cover;
		object-position: center center;
	}

	&:focus {
		@include focus-outline;
	}
}

.c-header__tools {
	@include flex-extend;
	flex: 1 0 auto;
	align-items: center;
	justify-content: flex-end;
	order: 3;
}

// Secondary Nav
.c-header__secondary-nav {
	@include bp-below($header-bp) {
		display: none;
	}
	@include bp($header-bp) {
		@include list-reset;
		display: flex;
		margin-left: -$bsu;
		margin-right: $bsu;

		margin-top: 6px;
	}
	@include bp("extra-large") {
		margin-top: 10px;
	}
}

.c-header__secondary-nav-item {
	padding-left: $bsu;
}

.c-header__secondary-nav-link {
	@include font-heading-12b;

	display: block;
	padding: $ssu 0;

	@include link-fancy;
}

// Language
.c-header__language {
	display: none;

	@include bp("medium") {
		display: block;
		padding-right: $bsu;
	}
}

.c-header__language-dropdown {
	@include font-base-16;
	display: block;
	padding: 0.65em;
	border: 1px solid $grey;
}


// Mobile buttons
$mobile-button-size: 45px;
$mobile-button-color: $brand;
$mobile-button--hover-color: darken($brand, 10);
$mobile-button-font-size: 25px;
$mobile-button-padding: math.div(($mobile-button-size - $mobile-button-font-size), 2);

.c-header__mobile-buttons {
	flex: 1 0 auto;
	text-align: right;

	@include bp("medium") {
		flex: 0 0 auto;
	}

	@include bp($header-bp--large) {
		display: none;
	}
}

.c-header__mobile-button {
	position: relative;
	width: $mobile-button-size;
	height: $mobile-button-size;
	padding: $mobile-button-padding 0;
	border: 0;
	background: none;
	transition: all 0.3s ease-in-out;
	cursor: pointer;
}

// Nav Toggle
.c-header__nav-toggle {
	@include bp($header-bp) {
		display: none;
	}

	b {
		transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, border-color 0.3s ease-in-out;
		position: absolute;
		left: $mobile-button-padding;
		display: block;
		width: $mobile-button-font-size;
		height: 0;
		border-top: 1px solid $mobile-button-color;

		&:nth-child(1) { top: $mobile-button-padding; }
		&:nth-child(2) { top: $mobile-button-padding + 12px; }
		&:nth-child(3) { top: $mobile-button-padding + 24px; }
	}

	&[aria-expanded="true"] {

		b {
			&:nth-child(1) { transform: translateY(12px) rotate(45deg); }
			&:nth-child(2) { opacity: 0; }
			&:nth-child(3) { transform: translateY(-12px) rotate(-45deg); }
		}
	}

	&:hover,
	&[aria-expanded="true"] {
		b {
			border-color: $mobile-button--hover-color;
		}
	}
}

// Search Toggle
.c-header__search-toggle {
	font-size: $mobile-button-font-size;
	color: $mobile-button-color;
	cursor: pointer;

	&[aria-expanded="true"] .iconf-search {
		&:before {
			content: $iconf-close;
		}
	}

	.iconf-search {
		position: absolute;
		left: $mobile-button-padding;
		top: $mobile-button-padding;
	}
}

.c-header__search-overlay {
	display: none;
	position: absolute;
	background: $white;
	top: $header-height;
	top: var(--header-height);
	left: 0;
	width: 100%;
	height: calc(100vh - #{$header-height});
	height: calc(100vh - var(--header-height));
	box-shadow: 0 8px 6px -6px rgba(0,0,0,0.2);

	@include bp($header-bp) {
		position: absolute;
		left: 0;
		top: $header-height;
		top: var(--header-height);
		height: auto;
	}
}

.c-header__search {
	@include bp-below($header-bp--large) {
		display: none;
	}
}

.c-header__search-wrapper {
	width: calc(100% - #{$bsu * 2});
	position: absolute;
	top: $lsu;
	left: 50%;
	transform: translate(-50%, 0);
	padding-bottom: 20px;

	@include bp($header-bp) {
		width: 700px;
		position: static;
		padding: 50px 0 80px;
		margin: 0 auto;
		top: auto;
		left: auto;
		transform: none;
	}
}

.c-header__search-field {
	position: relative;
	top: 5px;
}

.c-header__search-input {
	@include font-base-16;
	@include placeholder-text($grey);

	display: block;

	padding: $ssu $bsu;

	width: 100%;
	margin: 0 auto;

	border: 1px solid $grey;
	border-radius: 4px;

	background: transparent;

	&::-webkit-search-decoration,
	&::-webkit-search-cancel-button,
	&::-webkit-search-results-button,
	&::-webkit-search-results-decoration {
		display: none;
	}
}

.c-header__search-btn {
	@include button-reset;

	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);

	padding: 0;

	width: 50px;
	height: 50px;

	font-size: 16px;
	line-height: 1;
	color: $brand;

	&::before {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	@include link-transition;
	&:hover,
	&:focus {
		color: darken($brand, 10%);
		color: var(--secondary-a);
	}
}
