/* OBJECT.LAYOUT */

.o-layout-body {
	position: relative;
}

.o-layout-wrapper {
	@include layout-wrapper;
}

.o-layout-wrapper--narrow {
	max-width: $layout-wrapper-narrow-width;
	max-width: $layout-wrapper-narrow-width-vars;
}

.o-layout-page {
	position: relative;
	z-index: $z-page;
	padding-top: $bsu * 3;
}

.o-layout-content--has-aside {

	@include bp("large") {
		@include flex-extend;
	}
}

.o-layout-main {
	display: block;

	.o-layout-content--has-aside & {

		@include bp("large") {
			width: calc(100% - #{$layout-aside-width + $layout-aside-gutter-width});
			width: calc(100% - #{$layout-aside-width + $layout-aside-gutter-width-vars});
		}
	}
}

.o-layout-aside {
	margin-top: $lsu;

	@include bp("large") {
		width: $layout-aside-width + $layout-aside-gutter-width;
		width: calc($layout-aside-width + $layout-aside-gutter-width-vars);

		padding-left: $layout-aside-gutter-width;
		padding-left: $layout-aside-gutter-width-vars;

		margin-top: 0;

		&.is-left {
			padding-left: 0;
			padding-right: $layout-aside-gutter-width;
			padding-right: $layout-aside-gutter-width-vars;
		}
	}
}

.o-layout-header {
	margin-bottom: $hsu;
	text-align: center;

	> *:last-child {
		margin-bottom: 0;
	}
}

.o-layout-header--has-cta {
	// See _component.cta-intro.scss
	text-align: left;
	margin-bottom: $bsu;
}

.o-layout-header__subheading {
	display: block;
	margin-bottom: $ssu;
	@include font-base-18b;
}

.o-layout-header__heading {
	display: block;
	margin-bottom: 0.3em;
}

.o-layout-header__heading,
.o-layout-header__heading-text {
	@include font-heading-36b;
	@include bp("large") {
		@include font-heading-45b;
	}

	color: var(--primary-a);
}

.o-layout-header__heading-text {
	display: inline-block;
	vertical-align: middle;
}

.o-layout-header__highlight {
	display: inline-block;
	vertical-align: middle;

	margin-left: $bsu;

	@include font-base-16;
	padding: $tsu $ssu;
}

.o-layout-header__lede {
	@include font-base-20-tall;
	margin: 0 auto;

	> *:last-child {
		margin-bottom: 0;
	}
}

.o-layout-header__lede--full {
	width: auto;
}

.o-layout-header__date {
	@include font-base-12;
	margin-bottom: 2em;
}