// COMPONENT.FOOTER

@use "sass:math";

$footer-top-bp: "medium";
$footer-bottom-bp: "medium";

.c-footer {}

////////////////
// FOOTER TOP //
////////////////
.c-footer__top {}

.c-footer__top-layout {
	@include layout-wrapper;

	padding-top: $lsu;
	padding-bottom: $lsu;

	@include flex-extend;
	@include bp-below($footer-top-bp) {
		flex-direction: column;
	}
	align-items: center;

	@include bp($footer-top-bp) {
		background: url("/content/images/interface/bg/stairs.svg") no-repeat center right;
	}
}

.c-footer__top-text {
	display: block;
	@include bp-below($footer-top-bp) {
		margin-bottom: $bsu;
		text-align: center;
	}
	@include bp($footer-top-bp) {
		margin-right: $hsu;
	}

	@include font-heading-24b;
	@include bp($footer-top-bp) {
		@include font-heading-28b;
	}
}

.c-footer__top-button {
	white-space: nowrap;
	@include bp-below($footer-top-bp) {
		@include button("small");
		padding: $ssu $lsu;
	}
	@include bp($footer-top-bp) {
		@include button("medium");
		padding: $bsu 50px;
	}
}

///////////////////
// FOOTER BOTTOM //
///////////////////

.c-footer__bottom {
	background: $supporting-2-a;
	color: $supporting-2-b;

	background: var(--supporting-2-a);
	color: var(--supporting-2-b);

	@include bp-below($footer-bottom-bp) {
		padding: $bsu 0;
	}
	@include bp($footer-bottom-bp) {
		padding: $lsu 0 $lsu;
	}
}

.c-footer__bottom-layout {
	@include layout-wrapper;

	display: grid;
	grid-template-areas:
		"address"
		"social"
		"legal";

	grid-row-gap: $bsu;
	grid-column-gap: $lsu;

	@include bp($footer-bottom-bp) {
		grid-template-areas:
			"address social"
			"address legal";
	}
}

// Address //
.c-footer__address {
	grid-area: address;
	a {
		color: inherit;
		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}

	display: flex;
	flex-wrap: wrap;
	margin-left: -$lsu;
	margin-top: -$bsu;
}

.c-footer__address-item {
	padding-left: $lsu;
	padding-top: $bsu;
	@include bp-below($footer-bottom-bp) {
		width: 50%;
	}
}

.c-footer__address-item-title {
	display: block;
	margin-bottom: $bsu;

	@include font-heading-20b;
}

.c-footer__address-item-text {
	@include font-base-14;

	> :last-child {
		margin-bottom: 0;
	}
}

// Social //
.c-footer__social {
	grid-area: social;

	display: flex;
	@include bp-below($footer-bottom-bp) {
		justify-content: center;
	}
	@include bp($footer-bottom-bp) {
		justify-content: flex-end;
	}
}

.c-footer__social-list {
	@include list-reset;
}

.c-footer__social-item {
	display: inline-block;
	margin-right: $bsu;
}

.c-footer__social-link {
	@include circle(40px);
	@include link-transition;
	border: 1px solid currentColor;
	font-size: 20px;
}

// Legal //
.c-footer__legal {
	grid-area: legal;

	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
}

.c-footer__legal-links {
	@include list-reset;

	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;

	@include bp-below($footer-bottom-bp) {
		justify-content: space-between;
		margin: 0 #{-$bsu};
	}
}

.c-footer__legal-links-item {
	margin-bottom: $ssu;
	margin-right: $bsu;
	@include bp-below($footer-bottom-bp) {
		margin-left: $bsu;
	}
}

.c-footer__legal-link {
	display: block;

	@include font-heading-12b;
	color: inherit;

	&:focus,
	&:hover {
		text-decoration: underline;
	}
}

.c-footer__legal-copyright {
	display: block;

	@include font-heading-12;
}

// Back to top //
.c-footer-back-to-top {
	@include button("small", true);
	@include bp("large") {
		@include button-size("medium", true);
	}

	display: block;
	position: fixed;
	bottom: 30px;
	right: 0;
	z-index: $z-back-to-top;

	opacity: 0;
	visibility: hidden;
	transition: opacity 0.5s linear, visibility 0s linear 0.5s;
	&.fade-in {
		opacity: 1;
		visibility: visible;
		transition-delay: 0s;
	}

	@include bp("large") {
		position: relative;
		display: none;
		bottom: 0;
		vertical-align: bottom;
		opacity: 1;
		visibility: visible;

		&.sticky-on-desktop {
			display: inline-block;
			opacity: 0;
			visibility: hidden;

			&.fade-in {
				position: fixed;
				bottom: auto;
				top: 50%;

				transform: translateY(-50%);
				opacity: 1;
				visibility: visible;
			}
		}
	}
}
