﻿// CONTROL.PODS

$pod-bp: "medium";

.c-pod {
	height: 285px;
	display: flex;
	flex-direction: column;

	border-radius: 4px;
	overflow: hidden;

	color: $white;
	color: var(--primary-b);

	@include link-transition;
	@include scroll-appear {
		opacity: 0;
		transform: translateY(100px);
	}
}

.c-pod__head {
	@include link-fancy__hover-parent;

	flex-grow: 1;

	display: block;

	isolation: isolate;
	position: relative;
	overflow: hidden;

	color: inherit;

	&:hover,
	&:focus {
		.c-pod__image {
			transform: scale(1.1);
		}

		.c-pod__title-text::after {
			opacity: 1;
		}
	}
}

.c-pod__figure {
	display: block;
	height: 100%;

	position: relative;
	z-index: 1;

	&::after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;

		width: 100%;
		height: 100%;

		background-image: linear-gradient(0deg, $black 0%, transparent 200px);
		background-image: linear-gradient(0deg, var(--primary-a) 0%, transparent 200px);
	}
}

.c-pod__image {
	@include link-transition;

	// Height inherited instead from pod
	.c-pod & {
		padding-bottom: 0;
		height: 100%;
	}
}

.c-pod__title {
	display: inline-block;
	margin: $ssu $bsu;
	padding: $ssu calc(1em + #{$tsu}) $ssu 0;

	position: absolute;
	z-index: 2;
	bottom: 0;
	left: 0;

	@include font-heading-20b;
	@include link-transition;
}

.c-pod__title-text {
	@include link-arrow;
}
