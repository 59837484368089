// CONTROL.RTE

.c-rte {
	@include control;

	.c-two-column + & {
		// 1.5em is the standard paragraph spacing
		@include bp-below("large") {
			margin-top: calc(1.5em + - #{$lsu});
			margin-top: calc(1.5em + -1 * var(--control-gap));
		}
		@include bp("large") {
			margin-top: calc(1.5em + -#{$hsu});
			margin-top: calc(1.5em + -1 * var(--control-gap));
		}
	}
}

.mce-content-body,
.c-rte {
	@include clearfix;

	& + & {
		margin-top: $bsu;
	}
}

.c-rte__layout {
	@include layout-wrapper;
}

.c-rte__layout-inner {
	max-width: $layout-wrapper-narrow-width - $bsu * 2;
	max-width: calc($layout-wrapper-narrow-width-vars - #{$bsu * 2});
}

// RTE figure - shared styling across macros
.c-rte__figure {
	margin: $bsu auto;

	&.u-float--left {
		clear: left;

		@include bp("small") {
			margin-top: 0;
			margin-right: $bsu;
			margin-left: 0;
		}
	}

	&.u-float--right {
		clear: right;

		@include bp("small") {
			margin-top: 0;
			margin-right: 0;
			margin-left: $bsu;
		}
	}
}

.c-rte__figcaption {
	@include ui-border("bottom");
	padding: $ssu 0;
}
