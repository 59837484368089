﻿// COMPONENT.SHARE

$share-icon-size: 46px;
$share-icon-font-size: 20px;

$share: #51ae5e;
$facebook: #3b5997;
$twitter: #55acef;
$pinterest: #bd2126;
$google: #d34836;
$weibo: #fa7d3c;

.c-share {
	margin: $lsu auto;
	text-align: center;
}

.c-share__layout {
	@include layout-wrapper;
}

.c-share--floating {
	transition: left 0.3s ease-in;
	position: fixed;
	left: -#{$share-icon-size};
	top: 200px;
	z-index: $z-share;
	width: $share-icon-size;
	padding: 0;
	margin: 0;

	&.is-visible {
		left: 0;
	}
}

.c-share__heading {
	@include font-heading-18b;
	display: block;
	margin: 0 0 $ssu;

	.c-share--floating & {
		display: none;
	}
}

.c-share__items {
	@include list-reset;
	@include flex-extend;
	@include flex-wrap;
	justify-content: center;
	margin: -#{$ssu} 0 0 -#{$ssu};
}

.c-share__item {
	padding: $ssu 0 0 $ssu;
}

.c-share__icon {
	@include icon-wrapper;
	@include link-transition;
	@include circle($share-icon-size);

	display: block;
	font-size: $share-icon-font-size;
	text-align: center;
	vertical-align: middle;

	box-shadow: inset 0 0 0 1px currentColor;

	// &.iconf-facebook {
	// 	color: $facebook;

	// 	&:hover,
	// 	&:focus {
	// 		color: $white;
	// 		background: $facebook;
	// 	}
	// }

	// &.iconf-twitter {
	// 	color: $twitter;

	// 	&:hover,
	// 	&:focus {
	// 		color: $white;
	// 		background: $twitter;
	// 	}
	// }

	// &.iconf-pinterest {
	// 	color: $pinterest;

	// 	&:hover,
	// 	&:focus {
	// 		color: $white;
	// 		background: $pinterest;
	// 	}
	// }

	// &.iconf-google-plus {
	// 	color: $google;

	// 	&:hover,
	// 	&:focus {
	// 		color: $white;
	// 		background: $google;
	// 	}
	// }

	// &.iconf-weibo {
	// 	color: $weibo;

	// 	&:hover,
	// 	&:focus {
	// 		color: $white;
	// 		background: $weibo;
	// 	}
	// }
}
